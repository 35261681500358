<!--
 * @Author: your name
 * @Date: 2021-02-04 15:57:01
 * @LastEditTime: 2021-02-22 17:12:17
 * @LastEditors: Please set LastEditors
 * @Description: 库存变化表
 * @FilePath: \sd-vue-admin\src\views\project\treasury\depotData\inventoryChanges\index.vue
-->
<template>
  <!-- NAME[epic=库管] 库存变化表 -->
  <div style="padding: 20px">
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="depot_id">
          <el-select
            v-model="form.depot_id"
            placeholder="请选择仓库"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in warehouse"
              :key="index"
              :label="item.depot_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="inout_class">
          <el-select
            v-model="form.inout_class"
            placeholder="出入库类型"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in typecov"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="class_id">
          <el-select
            v-model="form.class_id"
            filterable
            placeholder="请选择品类"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Category"
              :key="index"
              :label="item.class_name_txt"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="brand_id">
          <brand-select
            ref="brandSelect"
            :duoxuan="true"
            style="width: 160px"
            @brand-select-change="brandChange"
          ></brand-select>
          <!-- <el-select
            v-model="form.brand_id"
            placeholder="请选择品牌"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Brand"
              :key="index"
              :label="item.brand_name"
              :value="item.id"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item prop="keyword_goods">
          <!--          <el-input-->
          <!--            v-model="form.keyword_goods"-->
          <!--            placeholder="商品名称，简拼"-->
          <!--          ></el-input>-->
          <goods-search
            ref="goodsSearch"
            :is-table="false"
            @select-goods-all="goodsSelect"
            @input-goods="goodsinputname"
          ></goods-search>
        </el-form-item>

        <el-form-item prop="Time">
          <el-date-picker
            v-model="form.Time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item prop="allot_code">
          <el-input v-model="form.allot_code" placeholder="单据号"></el-input>
        </el-form-item>
        <el-form-item prop="keyword_cust">
          <el-input
            v-model="form.keyword_cust"
            placeholder="客户名称、简拼"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handlersver">查 询</el-button>
          <el-button type="warning" @click="handlerReset">重 置</el-button>
          <el-button @click="handlerExport">导 出</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table v-loading="loading" stripe :data="tableData" :row-key="getRowKey" @selection-change="setSelectRows">
        <el-table-column
          align="center"
          :reserve-selection="true"
          type="selection"
          width="50"
        ></el-table-column>
        <el-table-column align="center" width="50">
          <!-- <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in colemd"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template> -->
          <template #default="{ row, $index }">
            <div v-if="row.bill_code == '合计'"></div>
            <div v-else>{{ $index + 1 }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        >
          <template #default="{ row }">
            <div
              v-if="list.label == '单号'"
              class="underline"
              @click="handleOrderDetail(row)"
            >
              {{ row[list.prop] }}
            </div>
            <div v-else>{{ row[list.prop] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col>
        <el-button type="primary" @click="SetAllot">批量调拨</el-button>
      </el-col>
      <el-col>
        <el-pagination
          background
          :current-page="form.pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </el-col>
    </el-row>
    <saleOrderDetail ref="saleOrderDetail"></saleOrderDetail>
    <advanceOrder ref="advanceOrder"></advanceOrder>
    <diaobodanOrder ref="diaobodanOrder"></diaobodanOrder>
    <rukuOrder ref="rukuOrder"></rukuOrder>
    <chukuOrder ref="chukuOrder"></chukuOrder>
    <baosunOrder ref="baosunOrder"></baosunOrder>
    <baoyiOrder ref="baoyiOrder"></baoyiOrder>
    <yudingdan ref="yudingdan"></yudingdan>
  </div>
</template>

<script>
  import { downloadFile, postAction } from '@/api/Employee'
  import BrandSelect from '@/baseComponents/brandSelect'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import saleOrderDetail from '@/views/project/sale/bills/saleOrder/components/checkOrder/index.vue'
  import advanceOrder from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  import diaobodanOrder from '@/views/project/treasury/requisition/documents/components/AddGoods.vue'
  import rukuOrder from '@/views/project/treasury/requisition/inquire/components/AddWarehousing.vue'
  import chukuOrder from '@/views/project/treasury/requisition/Outstock/components/AddWarehousing.vue'
  import baosunOrder from '@/views/project/treasury/requisition/Lossreport/components/goodslink.vue'
  import baoyiOrder from '@/views/project/treasury/requisition/baoYiDan/component/create.vue'
  import yudingdan from '@/views/project/sale/bills/advanceOrder/components/checkOrder/index.vue'
  import dateFormat from '@/utils/Timeformat'
  import {parseStringToObjects} from "@/utils/ToObjects";
  import {toFinite} from "lodash/lang";
  export default {
    components: {
      BrandSelect,
      saleOrderDetail,
      advanceOrder,
      diaobodanOrder,
      rukuOrder,
      chukuOrder,
      baosunOrder,
      baoyiOrder,
      yudingdan,
      GoodsSearch,
    },
    data() {
      return {
        loading: false,
        form: {
          depot_id: '', //仓ID
          allot_code: '', //单号
          start_time: '', //开始时间 如2020-10-1
          end_time: '', //结束时间 如2020-12-31
          Time: [],
          inout_class: '', //出入库类型
          brand_id: '', //品牌ID
          class_id: '', //品类ID
          keyword_goods: '', //产品关键字 简拼
          keyword_cust: '', //店铺关键字 简拼
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        warehouse: [],
        Category: [],
        Brand: [],
        Typesof: [],
        outtypeof: [],
        status: [],
        typecov: [
          {
            id: 1,
            name: '销售出库',
          },
          {
            id: 2,
            name: '直销出库',
          },
          {
            id: 3,
            name: '调拨出库',
          },
          {
            id: 4,
            name: '盘亏出库',
          },
          {
            id: 5,
            name: '采退出库',
          },
          {
            id: 6,
            name: '报损出库',
          },
          {
            id: 7,
            name: '拆装出库',
          },
          {
            id: 8,
            name: '其它出库',
          },
          {
            id: 9,
            name: '采购入库',
          },
          {
            id: 10,
            name: '调拨入库',
          },
          {
            id: 11,
            name: '退货入库',
          },
          {
            id: 12,
            name: '盘盈入库',
          },
          {
            id: 13,
            name: '拆装入库',
          },
          {
            id: 14,
            name: '其它入库',
          },
        ],
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          Category: '/baseAdmin/common/goods-class-list',
          Brand: '/baseAdmin/common/brand-list',
          list: '/depotAdmin/bill-allot/depot-stock-list',
          Typesof: '/baseAdmin/common/bill-in-type',
          outtypeof: '/baseAdmin/common/bill-out-type',
          status: '/baseAdmin/common/allot-status',
        },
        tableData: [],
        selectRows:[],
        checkList: [
          '单号',
          '仓库',
          '类型',
          '客户/供应商',
          '时间',
          '商品编码',
          '商品名称',
          '单位换算',
          '增加数量',
          '减少数量',
          '库存数量',
        ],
        colemd: [
          {
            order: 1,
            label: '单号',
            align: 'center',
            prop: 'allot_code',
            width: '',
          },
          {
            order: 2,
            label: '仓库',
            align: 'center',
            prop: 'depot_name',
            width: '',
          },
          {
            order: 3,
            label: '类型',
            align: 'center',
            prop: 'type_name',
            width: '',
          },
          {
            order: 4,
            label: '客户/供应商',
            align: 'center',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 5,
            label: '时间',
            align: 'center',
            prop: 'add_time',
            width: '',
          },
          {
            order: 5.5,
            label: '商品编码',
            align: 'center',
            prop: 'goods_id',
            width: '',
          },
          {
            order: 6,
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },

          {
            order: 7,
            label: '单位换算',
            align: 'center',
            prop: 'goods_convert',
            width: '',
          },
          {
            order: 8,
            label: '规格',
            align: 'center',
            prop: 'goods_spec',
            width: '',
          },
          {
            order: 9,
            label: '变化数量',
            align: 'center',
            prop: 'in_out_count',
            width: '',
          },

          {
            order: 10,
            label: '库存数量',
            align: 'center',
            prop: 'balance_count',
            width: '',
          },
        ],
        footer: {},
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.colemd.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
      selectRowsAll(){
        const mergedProducts = {};
        this.selectRows.map((item) => {
            if(mergedProducts[item.goods_id]){
              mergedProducts[item.goods_id].balance_count_min += item.balance_count_min
            }else {
              mergedProducts[item.goods_id] = item
            }
        })
        return Object.values(mergedProducts)
      }
    },
    watch: {
      'form.Time'(val) {
        console.log(val)
        try {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } catch (error) {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    async mounted() {
      if (
        this.$route.query.goods_name &&
        this.$route.query.goods_id &&
        this.$route.query.depot_id
      ) {
        this.$refs.goodsSearch.goods_keyword = this.$route.query.goods_name
        this.form.goods_id = this.$route.query.goods_id
        this.form.keyword_goods = this.$route.query.goods_name
        this.form.depot_id = Number(this.$route.query.depot_id)
        this.form.Time = [
          dateFormat(new Date().getTime() - 3600000 * 24 * 30),
          dateFormat(new Date().getTime()),
        ]
      }
      console.log(this.$route.query, '路由传参')

      await this.handlerwarehouse()
      await this.handlerCategory()
      await this.handlerBrand()
      await this.handlerlist()
      // this.handlerstatus()
      await this.handlerTypesof()
      await this.handleroutesof()
    },
    methods: {
      SetAllot(){
        if(this.selectRows.length<=0){
          this.$message('请选择要调拨的行')
        }else{
          let goodsLists = this.convertUnits(this.selectRowsAll);
          let goodsArrers=goodsLists.map(item=>{
            console.log('item',item)
            item.parsedData=parseStringToObjects(item.balance_count);
            return item
          })
          console.log(this.mergeData(goodsArrers),goodsLists,"xiugai")
          const warehouse = this.mergeData(goodsArrers)
          const depot_id = this.form.depot_id?JSON.parse(JSON.stringify(this.form.depot_id)):warehouse[0].depot_id
          this.$router.push({name:'Adddocu',params:{
              cklist:this.warehouse,
              goodsList:this.mergeData(goodsArrers),
              depot_id:depot_id,
          }
          })
        }
      },
      mergeData(goodsData){
        const mergedData = [];
        goodsData.forEach((item) => {
          item.parsedData.forEach(list=>{
           item.unit_id = item.goods_cv.filter(fil=>fil.unit_name==list.unit)[0].unit_id
            item.quantity = list.num
            item.goods_price=item.goods_cv.filter(fil=>fil.unit_name==list.unit)[0].goods_price
            item.goods_money = (Number(item.quantity)*Number(item.goods_price)).toFixed(2)
            item.specs=item.goods_spec
            item.arr_unit=item.goods_cv
            item.arr_unit.forEach((unit) => {
              unit.id=unit.unit_id
              return unit
            })
            item.goods_type=1
            const newData = {...item}
            // delete newData.parsedData;
            mergedData.push(newData)
          })
        })
        return mergedData;
      },
      convertUnits(products){
        const convertedProducts=products.map(item=>{
          let largeUnit,mediumUnit, smallUnit='';
          if(item.goods_cv.length==1){
            item.balance_count=item.balance_count_min+item.goods_cv[0].unit_name
          }else if(item.goods_cv.length==2){
            if(Math.floor(item.balance_count_min/item.goods_cv[1].cv)) {
              largeUnit = Math.floor(item.balance_count_min / item.goods_cv[1].cv) + item.goods_cv[1].unit_name
              if(item.balance_count_min%item.goods_cv[1].cv){
                smallUnit = item.balance_count_min % item.goods_cv[1].cv + item.goods_cv[0].unit_name
              }
              item.balance_count=largeUnit+smallUnit
            }else{
              smallUnit = item.balance_count_min + item.goods_cv[0].unit_name
              item.balance_count=smallUnit
            }
          }else if (item.goods_cv.length==3) {
            if (Math.floor(item.balance_count_min / item.goods_cv[2].cv)) {
              largeUnit = Math.floor(item.balance_count_min / item.goods_cv[2].cv) + item.goods_cv[2].unit_name
              if (item.balance_count_min % item.goods_cv[2].cv) {
                  if(Math.floor((item.balance_count_min % item.goods_cv[2].cv)/item.goods_cv[1].cv)){
                    mediumUnit = Math.floor((item.balance_count_min % item.goods_cv[2].cv) / item.goods_cv[1].cv) + item.goods_cv[1].unit_name
                    if((item.balance_count_min % item.goods_cv[2].cv) % item.goods_cv[1].cv){
                      smallUnit = (item.balance_count_min % item.goods_cv[2].cv) % item.goods_cv[1].cv + item.goods_cv[0].unit_name
                      item.balance_count=largeUnit+mediumUnit+smallUnit
                    }
                    item.balance_count=largeUnit+mediumUnit

                  }else{
                    smallUnit = item.balance_count_min + item.goods_cv[0].unit_name
                    item.balance_count=smallUnit
                  }
              }
            }else{
              if(Math.floor(item.balance_count_min/item.goods_cv[1].cv)) {
                largeUnit = Math.floor(item.balance_count_min / item.goods_cv[1].cv) + item.goods_cv[1].unit_name
                if(item.balance_count_min%item.goods_cv[1].cv){
                  smallUnit = item.balance_count_min % item.goods_cv[1].cv + item.goods_cv[0].unit_name
                }
                item.balance_count=largeUnit+smallUnit
              }else{
                smallUnit = item.balance_count_min + item.goods_cv[0].unit_name
                item.balance_count=smallUnit
              }
            }
          }
          return item
        })
        return convertedProducts
      },
      getRowKey(row) {
        return row.id
      },
      // 勾选表格触发事件
      setSelectRows(val) {
        console.log(val)
        this.selectRows = val
      },
      goodsSelect(val) {
        console.log(val)
        this.form.goods_id = val.goods_id
        this.form.keyword_goods = val.goods_name_changes
      },
      goodsinputname(val) {
        console.log('可能没有触发', val)
        this.form.keyword_goods = val
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '总计'
            return
          }

          sums[8] = this.footer.in_count
          sums[9] = this.footer.out_count
          sums[10] = this.footer.balance_count

          // const values = data.map((item) => Number(item[column.property]))
          // if (!values.every((value) => isNaN(value))) {
          //   sums[index] = values.reduce((prev, curr) => {
          //     const value = Number(curr)
          //     if (!isNaN(value)) {
          //       return prev + curr
          //     } else {
          //       return prev
          //     }
          //   }, 0)
          //   sums[index] += ' 元'
          // } else {
          //   sums[index] = 'N/A'
          // }
        })

        return sums
      },
      handlerTypesof() {
        postAction(this.url.Typesof, {})
          .then((res) => {
            console.log(res, '入库类型')
            this.Typesof = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleroutesof() {
        postAction(this.url.outtypeof, {})
          .then((res) => {
            console.log(res, '出库类型')
            this.outtypeof = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //仓库
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //品类
      handlerCategory() {
        postAction(this.url.Category, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品类')
            this.Category = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //品牌
      handlerBrand() {
        postAction(this.url.Brand, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品牌')
            this.Brand = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },

      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlersver() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerReset() {
        this.$refs['form'].resetFields()
        this.$refs.brandSelect.resetForm()
        this.handlerlist()
      },
      handlerlist() {
        this.loading = true
        var data = JSON.parse(JSON.stringify(this.form))
        delete data.Time
        postAction(this.url.list, data)
          .then((res) => {
            console.log(res)
            this.tableData = res.data.rows
            this.loading = false
            this.total = res.totalCount
            this.footer = res.data.footer
          })
          .catch((err) => {
            this.loading = false
            console.log(err)
          })
      },
      brandChange(val) {
        this.form.brand_id = val
      },
      handlerExport() {
        downloadFile(
          '/depotAdmin/bill-allot/export-depot-stock-list',
          '库存变化表.xlsx',
          this.form
        )
      },
      handleOrderDetail(row) {
        console.log(row)
        // 销售单XS  调拨单DB   入库单RK  出库单CK  报损单BS  报溢单BY
        // advanceOrder
        // diaobodanOrder
        // rukuOrder
        // chukuOrder
        // baosunOrder
        // baoyiOrder
        switch (row.bill_type) {
          case 'XS':
            this.$refs['saleOrderDetail'].isshowDialog = true
            this.$refs['saleOrderDetail'].id = row.bill_id
            break
          case 'DB':
            row.id = row.bill_id
            this.$refs.diaobodanOrder.dialogVisible = true
            this.$refs.diaobodanOrder.type = 1
            this.$refs.diaobodanOrder.handlerlist(row)
            break
          case 'RK':
            row.url_id = row.bill_id
            this.$refs.rukuOrder.type(1)
            this.$refs.rukuOrder.handlercreateData(row)
            this.$refs.rukuOrder.showAdd = true
            break
          case 'CK':
            row.url_id = row.bill_id
            this.$refs.chukuOrder.type(1)
            this.$refs.chukuOrder.handlercreateData(row) //url_id
            this.$refs.chukuOrder.showAdd = true
            break
          case 'BS':
            row.id = row.bill_id
            this.$refs.baosunOrder.showlink = true
            this.$refs.baosunOrder.isEdit = true
            this.$refs.baosunOrder.handlerinfo(row) //id
            break
          case 'BY':
            this.$refs.baoyiOrder.showDialog = true
            this.$refs.baoyiOrder.id = row.bill_id
            // type1添加 2查看 3审核
            this.$refs.baoyiOrder.type = 2
            this.$refs.baoyiOrder.title = '报溢单详情'
            break
          case 'XD':
            this.$refs['yudingdan'].id = row.bill_id
            this.$refs['yudingdan'].orderStatus2 = row.check_status_text
            this.$refs['yudingdan'].showDialog()
            // row.id = row.bill_id
            // this.$refs.baosunOrder.showlink = true
            // this.$refs.baosunOrder.isEdit = true
            // this.$refs.baosunOrder.handlerinfo(row) //id
            break
        }
      },
    },
  }
</script>

<style></style>
